@import "../node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
    font-family: 'icomoon';
    src: url('/css/fonts/icomoon.eot');
    src: url('/css/fonts/icomoon.eot#iefix') format('embedded-opentype'), 
        url('/css/fonts/icomoon.ttf') format('truetype'), 
        url('/css/fonts/icomoon.woff') format('woff'), 
        url('/css/fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "brandpulsebold";
    src: url("/css/fonts/brandpulse_bold.woff2") format("woff2"), url("/css/fonts/brandpulse_bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "brandpulsebold_italic";
    src: url("/css/fonts/brandpulse_bold_italic.woff2") format("woff2"), url("/css/fonts/brandpulse_bold_italic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #aaa;

    &:hover {
        color: #000;
    }
}

.icon-copy:before {
    content: "\e904";
}

.icon-edit:before {
    content: "\e900";
}

.icon-delete:before {
    content: "\e901";
}

.icon-chart:before {
    content: "\e902";
}

.icon-qrcode:before {
    content: "\e903";
}


body {

    .copy {
        text-decoration: none;
        color: #000;

        span {
            opacity: 0;
        }

        &:hover {
            span {
                opacity: 1;
                color: #aaa;
            }
        }
    }

    .link-body-emphasis {
        text-decoration: none !important;

        &:hover {
            color: #aaa !important;
        }
    }

    .form {
        background: #eee;
    }

    h1, h2 {
        font: normal 3vw/1 'brandpulsebold', Arial;
        text-transform: uppercase;
        white-space: nowrap;

        span {
            transform: rotateX(180deg);
            display: inline-block;
            position: relative;
            top: 0;
        }
    }

    h1 {
        color: #E03C31;
    }

    h2 {
        font-size: 1.75vw;
    }



    .text-bg-danger, .text-bg-info {
        border-radius: 5px;
    }

    .btn-link {
        text-decoration: none;
        color: #000;

        &:hover {
            color: #aaa !important;
        }
    }

    .btn-close {
        right: 15px;
        top: 0;
    }

    @include media-breakpoint-down(md) {
        h1, h2 {
            font-size: 8vw;
        }

        .profile {
            text-align: left !important;
            float: none !important;
            padding-left: 0 !important;
        }

        .btn-close {
            padding-top: 0.5em !important;
        }
    }
}
